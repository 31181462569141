@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #1f2937;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ffd900;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffd900;
}